import {types} from "mobx-state-tree"
import languageModel from "@store/models/language.model"
import layoutRequests from "@app/services/http/requests/layout"
import {flow} from "mobx"

const countryModel = types.model("country", {
    id: types.optional(types.number, 0),
    name: types.optional(types.string, "")
});

const uiModel = types.model("ui", {
    languages: types.optional(types.array(languageModel), []),
    countries: types.optional(types.array(countryModel), [])
}).actions((self) => ({
    loadLanguages: flow(function* load() {
        try {
            const response = yield layoutRequests.languages();
            self.setLanguages(response.data.data);
        } catch(e) {
            console.error("Failed to load languages");
        }
    }),

    loadCountries: flow(function* load() {
        try {
            const response = yield layoutRequests.countries();
            self.setCountries(response.data.data);
        } catch(e) {
            console.error("Failed to load countries");
        }
    }),

    setLanguages: (value) => {
        self.languages = value;
    },

    setCountries: (value) => {
        self.countries = value;
    }
}));

export default uiModel;
