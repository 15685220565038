import {types} from "mobx-state-tree"

const eventRouteModel = types.model("eventRoute", {
    id: types.optional(types.number, 0),
    title: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    distance: types.optional(types.string, ""),
    gpx_url: types.optional(types.string, ""),
    thumbnail_url: types.optional(types.string, ""),
    start_at: types.optional(types.string, ""),
    price: types.optional(types.number, 0),
    members_count: types.optional(types.number, 0),
    members_limit: types.optional(types.number, 0),
});

export default eventRouteModel;
