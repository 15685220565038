import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useScreenStore} from "@lib/store"
import partnersScreen from "@store/models/partners.screen"
import {observer} from "mobx-react"
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Grid,
    GridItem,
    Heading,
    Skeleton,
    Spacer,
    useBoolean,
    useToast
} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import PartnersItem from "@ui/partners-item"
import {computed} from "mobx"
import EventsActionRouteModal from "@features/events/components/action-route-modal"
import PartnersActionModal from "@features/partners/components/action-modal"

const PartnersScreen = observer(() => {
    const model = useScreenStore(partnersScreen, {});
    const { t: partnersT } = useTranslation("partners");

    const [activePartner, setActivePartner] = useState(null);
    const [openedActionModal, manipulateOpenedActionModal] = useBoolean(false);

    const toast = useToast();

    useEffect(() => {
        model.load();
    }, []);

    const createPartner = useCallback(() => {
        setActivePartner(null);
        manipulateOpenedActionModal.on();
    }, []);

    const editPartner = useCallback((id) => {
        setActivePartner(model.items.find((v) => v.id === id));
        manipulateOpenedActionModal.on();
    }, [setActivePartner, model.items]);

    const deletePartner = useCallback(async (id) => {
        try {
            await model.deletePartner(id);
            await model.updatePartners();

            toast({
                title: partnersT("deleted_success"),
                status: "success",
            });
        } catch(e) {
            console.log(e);
            toast({
                title: partnersT("error_message"),
                description: `Error code: ${e.response?.data?.data?.code}`,
                status: "error",
            });
        }
    }, [toast, model, partnersT]);

    const onActionInModal = useCallback(async (values, imageFile) => {
        try {
            if(activePartner) {
                await model.updatePartner(activePartner.id, values, imageFile);
            } else {
                await model.createPartner(values, imageFile);
            }

            await model.updatePartners();

            if(activePartner) {
                toast({
                    title: partnersT("updated_success"),
                    status: "success",
                });
            } else {
                toast({
                    title: partnersT("created_success"),
                    status: "success",
                });
            }
        } catch(e) {
            console.log(e);
            toast({
                title: partnersT("error_message"),
                description: `Error code: ${e.response?.data?.data?.code}`,
                status: "error",
            });
        }
    }, [activePartner, toast, partnersT]);

    const renderedPartners = useMemo(() => {
        return computed(() => {
            return model.items.slice().map((item) => {
                return (
                    <PartnersItem partnerID={item.id}
                                  imageURL={item.image_url}
                                  onEdit={editPartner}
                                  onDelete={deletePartner} />
                );
            });
        })
    }, [model.items, editPartner, deletePartner]);

    if(!model.loaded) {
        return (
            <Skeleton height={"180px"} />
        );
    }

    return (
        <>
            <PartnersActionModal partner={activePartner}
                                 isOpen={openedActionModal}
                                 onAction={onActionInModal}
                                 onClose={manipulateOpenedActionModal.off} />

            <Flex bg={"white"} p={4} direction={"column"}>
                <Flex minWidth='max-content' alignItems='center' gap='2' mb={4}>
                    <Box p='2'>
                        <Heading size='md'>{partnersT("title")}</Heading>
                    </Box>
                    <Spacer />
                    <ButtonGroup gap='2'>
                        <Button colorScheme='green' onClick={createPartner}>{partnersT("create")}</Button>
                    </ButtonGroup>
                </Flex>

                <Grid templateColumns={"repeat(8, 1fr)"} gap={5}>
                    {renderedPartners.get()}
                </Grid>
            </Flex>
        </>
    )
});

export default PartnersScreen;
