import React, {useCallback, useContext, useMemo} from 'react'
import {LocalStoreContext, useStore} from "@lib/store"
import {computed} from "mobx"
import {GENDER_LIST} from "@features/events/genders"
import {USER_CLOTHING_SIZE_LIST} from "@features/events/clothSizes"
import Control from "@ui/control"
import {Flex, FormControl, FormLabel, IconButton, Select} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import { FaSearch} from "react-icons/fa"
import {observer} from "mobx-react"

const UsersFilter = observer((props) => {
    const {
        reloadUsers
    } = props

    const store = useContext(LocalStoreContext);
    const rootStore = useStore();

    const { t: usersT } = useTranslation("users");

    const onChangeQuery = useCallback((text) => {
        return store.filter.onChangeText("query", text);
    }, [store.filter]);

    const onChangeGender = useCallback((ev) => {
        return store.filter.onChangeText("gender", ev.target.value);
    }, [store.filter]);

    const onChangeClothSize = useCallback((ev) => {
        return store.filter.onChangeText("cloth_size", ev.target.value);
    }, [store.filter]);

    const onChangeBirthdayDate = useCallback((text) => {
        return store.filter.onChangeText("birthday_date", text);
    }, [store.filter]);

    const onChangeCountry = useCallback((ev) => {
        return store.filter.onChangeText("country_id", ev.target.value);
    }, [store.filter]);

    const renderedCountries = useMemo(() => {
        return rootStore.ui.countries.map((value) => {
            return (
                <option value={value.id} key={`gender-${value.id}`}>
                    {value.name}
                </option>
            )
        });
    }, []);

    const renderedGenders = useMemo(() => {
        return GENDER_LIST.map((value) => {
            return (
                <option value={value.id} key={`gender-${value.id}`}>
                    {value.name}
                </option>
            )
        });
    }, []);

    const renderedClothSizes = useMemo(() => {
        return USER_CLOTHING_SIZE_LIST.map((value) => {
            return (
                <option value={value.id} key={`cloth-size-${value.id}`}>
                    {value.name}
                </option>
            )
        });
    }, []);

    return (
        <Flex direction={"row"}
              gap={3} p={3}
              alignItems={"flex-end"}>
            <Control inputID={"query"}
                     label={usersT("query_field")}
                     value={store.filter.values.get("query") || ''}
                     onChange={onChangeQuery}/>

            <FormControl id={"gender"}>
                <FormLabel>{usersT("gender")}</FormLabel>
                <Select value={store.filter.values.get("gender")}
                        onChange={onChangeGender}>
                    <option value={''}>
                        {usersT("select")}
                    </option>
                    {renderedGenders}
                </Select>
            </FormControl>


            <FormControl id={"country_id"}>
                <FormLabel>{usersT("country")}</FormLabel>
                <Select value={store.filter.values.get("country_id")}
                        onChange={onChangeCountry}>
                    <option value={''}>
                        {usersT("select")}
                    </option>
                    {renderedCountries}
                </Select>
            </FormControl>


            <FormControl id={"cloth_size"}>
                <FormLabel>{usersT("cloth_size")}</FormLabel>
                <Select value={store.filter.values.get("cloth_size")}
                        onChange={onChangeClothSize}>
                    <option value={''}>
                        {usersT("select")}
                    </option>
                    {renderedClothSizes}
                </Select>
            </FormControl>

            <FormControl id={"birthday_date"}>
                <Control inputID={"birthday_date"}
                         label={usersT("birthday_date")}
                         type={"date"}
                         value={(store.filter.values.get("birthday_date") || '')}
                         onChange={onChangeBirthdayDate}/>
            </FormControl>

            <IconButton  icon={<FaSearch />}
                         onClick={reloadUsers}/>
        </Flex>
    )
});

export default UsersFilter;
