import {types} from "mobx-state-tree"

const eventMemberModel = types
    .model("eventMember", {
        display_name: types.optional(types.string, ''),
        route_name: types.optional(types.string, ''),
        country_name: types.optional(types.string, ''),
        gender: types.optional(types.string, ''),
        cloth_size: types.optional(types.string, ''),
        city_name: types.optional(types.string, ''),
        birthday_date: types.optional(types.string, ''),
        phone: types.optional(types.string, ''),
        payment_id: types.optional(types.number, 0),
        user_id: types.optional(types.number, 0)
    });

export default eventMemberModel;
