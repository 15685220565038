import eventMemberModel from "@store/models/events/eventMember"

const {types} = require("mobx-state-tree")

const eventMembersTableHeaderModel = types
    .model("EventMembersTableHeader", {
        key: types.optional(types.string, ''),
        label: types.optional(types.string, '')
    });


const eventMembersTableModel = types.model("eventMembersTable", {
    headers: types.optional(types.array(eventMembersTableHeaderModel), []),
    data: types.optional(types.array(eventMemberModel), []),
});

export default eventMembersTableModel;
