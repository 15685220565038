import React, {useCallback} from 'react'
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Button,
    useColorModeValue,
    FormErrorMessage, useToast,
} from '@chakra-ui/react'
import {useTranslation} from "react-i18next"
import {useScreenStore, useStore} from "@lib/store"
import loginScreenModel from "@store/models/login.screen"
import {renderError} from "@lib/form"
import {observer} from "mobx-react"
import {useNavigate} from "react-router"

const Login = observer(() => {
    const store = useStore();
    const toast = useToast()

    /** @type {loginScreenModel} */
    const model = useScreenStore(loginScreenModel);

    const { t } = useTranslation("login");
    const { t: fieldsT } = useTranslation("fields");

    const navigate = useNavigate();

    const onChangeEmail = useCallback((e) => model.form.onChangeText("email", e.target.value), [model.form]);
    const onChangePassword = useCallback((e) => model.form.onChangeText("password", e.target.value), [model.form]);

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();

        if(!await model.form.validate()) {
            return false;
        }

        model.setDisabled(true);
        const result = await model.login();

        // login success
        if(result instanceof Object) {
            store.user.setAuthTokenToStorage(result.auth_token);
            store.user.setUser(result.user);
            store.user.setIsAuthorized(true);

            setTimeout(() => {
                navigate("/");
            }, 300);
        } else {
            // else returns error code
            switch(result) {
                case -2:
                    toast({
                        title: t("forbidden_title"),
                        description: t("forbidden_description"),
                        status: "error",
                    });
                    break
                case -3:
                    model.form.addError("email", t("invalid_email_or_password"));
                    break
                default:
                    break
            }
        }

        model.setDisabled(false);
    }, [model, t, toast, navigate, store.user]);

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>

            <Stack spacing={8} mx={'auto'} w={"3xl"} py={12} px={12}>
                <Box
                    rounded={'lg'}
                    w={"3xl"}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                       <form onSubmit={onSubmit}>
                           <FormControl id="email" isInvalid={!!model.form.errors.get("email")}>
                               <FormLabel>{t("email")}</FormLabel>
                               <Input type="email"
                                      value={model.form.values.get("email") || ''}
                                      onChange={onChangeEmail} />

                               <FormErrorMessage>
                                   {renderError(fieldsT, model.form.errors.get("email"))}
                               </FormErrorMessage>
                           </FormControl>
                           <FormControl id="password" mt={5} isInvalid={!!model.form.errors.get("password")}>
                               <FormLabel>{t("password")}</FormLabel>
                               <Input type="password"
                                      value={model.form.values.get("password") || ''}
                                      onChange={onChangePassword} />

                               <FormErrorMessage>
                                   {renderError(fieldsT, model.form.errors.get("password"))}
                               </FormErrorMessage>
                           </FormControl>
                           <Stack spacing={10} mt={5}>
                               <Button
                                   isLoading={model.disabled}
                                   bg={'blue.400'}
                                   color={'white'}
                                   _hover={{
                                       bg: 'blue.500',
                                   }}
                                    onClick={onSubmit}>
                                   {t("login")}
                               </Button>
                           </Stack>
                       </form>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    )
});

export default Login;
