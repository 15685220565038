import {types} from "mobx-state-tree"
import eventMembersTableModel from "@store/models/events/eventMembersTable.model"
import { object } from 'yup'
import {formModel} from "@lib/form"
import {flow} from "mobx"
import paginationModel from "@store/models/pagination"
import eventItem from "@store/models/events/eventItem"
import eventRequests from "@app/services/http/requests/event"
import eventEditEventModel from "@store/models/event-edit/event.model"

const $schema = () => {
    return object({

    });
};

const eventMembersScreen = types.model("eventMembers", {
    loaded: types.optional(types.boolean, false),
    event: types.optional(eventEditEventModel, {}),
    table: types.optional(eventMembersTableModel, {}),
    filter: types.optional(formModel($schema()), {}),
    pagination: types.optional(paginationModel, {}),
}).actions((self) => ({
    setLoaded: (value) => {
        self.loaded = value;
    },

    setEvent: (value) => {
        self.event = value;
    },

    createTable: (table) => {
        self.table = table;
    },

    load: flow(function* load(eventID) {
        try {
            const response = yield eventRequests.get(eventID);
            self.setEvent(response.data.data);
        } catch(e) {
            console.error("Failed to load event", e);
        }
    }),

    exportToExcel: flow(function* exp() {
        try {
            eventRequests.toExcel(self.event.id, Object.fromEntries(self.filter.values)).then (response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'file.xlsx'
                link.click()
            })
        } catch(e) {
            console.error("Failed to dowload", e);
        }
    }),

    loadMembers: flow(function* loadMembers() {
        try {
            const response = yield eventRequests.members(
                self.event.id,
                self.pagination.page,
                self.pagination.limit,
                Object.fromEntries(self.filter.values)
            );

            self.pagination.setPagination(
                parseInt(response.headers['x-pagination-page']),
                parseInt(response.headers['x-pagination-limit']),
                parseInt(response.headers['x-pagination-total-count']),
            )

            self.createTable(response.data.data);
        } catch(e) {
            console.error("Failed to load event members", e);
        }
    })
}));

export default eventMembersScreen;
