import {types} from "mobx-state-tree"

export const eventLegalLinkModel = types.model("eventLegalLink", {
    id: types.optional(types.number, 0),
    title: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    button_text: types.optional(types.string, ""),
    button_link: types.optional(types.string, ""),
}).actions((self) => ({
    setTitle: (value) => {
        self.title = value;
    },

    setDescription: (value) => {
        self.description = value;
    },

    setButtonText: (value) => {
        self.button_text = value;
    },

    setButtonLink: (value) => {
        self.button_link = value;
    }
}));

export default eventLegalLinkModel;
