export default (t, error) => {
    if(!error) {
        return "";
    }

    if(typeof error === "object") {
        return t(error[0].key, error[0].values);
    }

    return t(error);
};
