import React, {useCallback, useMemo} from 'react'
import {Flex, Grid, GridItem} from '@chakra-ui/react'
import PaginationItem from "@shared-ui/pagination-item"

const Pagination = (props) => {
    const {
        currentPage = 0,
        pageCount,
        onChange,
    } = props;

    const renderedPages = useMemo(() => {
        return new Array(pageCount).fill(null).map((_, i) => {
            const pageNumber = i+1;

            return (
                <PaginationItem page={pageNumber}
                                active={pageNumber === currentPage}
                                key={`pagination-page-${pageNumber}`}
                                onClick={onChange} />
            )

        });
    }, [currentPage, pageCount, onChange]);

    return (
        <Flex gap={3}>
            {renderedPages}
        </Flex>
    )
};

export default Pagination;
