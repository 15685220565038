import React, {useCallback, useContext, useMemo} from 'react'
import {LocalStoreContext, useStore} from "@lib/store"
import {computed} from "mobx"
import {GENDER_LIST} from "@features/events/genders"
import {USER_CLOTHING_SIZE_LIST} from "@features/events/clothSizes"
import Control from "@ui/control"
import {Flex, FormControl, FormLabel, IconButton, Select} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import { FaSearch} from "react-icons/fa"
import {observer} from "mobx-react"
import {renderError} from "@lib/form"

const EventMembersFilter = observer((props) => {
    const {
        reloadMembers
    } = props

    const store = useContext(LocalStoreContext);
    const rootStore = useStore();

    const { t: eventMembersT } = useTranslation("event_members");

    const onChangeQuery = useCallback((text) => {
        return store.filter.onChangeText("query", text);
    }, [store.filter]);

    const onChangeRouteID = useCallback((ev) => {
        return store.filter.onChangeText("route_id", ev.target.value);
    }, [store.filter]);

    const onChangeGender = useCallback((ev) => {
        return store.filter.onChangeText("gender", ev.target.value);
    }, [store.filter]);

    const onChangeClothSize = useCallback((ev) => {
        return store.filter.onChangeText("cloth_size", ev.target.value);
    }, [store.filter]);

    const renderedRoutes = useMemo(() => {
        return computed(() => {
            return store.event.routes.map((route) => {
                return (
                    <option value={route.id} key={`route-${route.id}`}>
                        {route.title}
                    </option>
                )
            });
        });
    }, [store.event.routes]);

    const renderedGenders = useMemo(() => {
        return GENDER_LIST.map((value) => {
            return (
                <option value={value.id} key={`gender-${value.id}`}>
                    {value.name}
                </option>
            )
        });
    }, []);

    const renderedClothSizes = useMemo(() => {
        return USER_CLOTHING_SIZE_LIST.map((value) => {
            return (
                <option value={value.id} key={`cloth-size-${value.id}`}>
                    {value.name}
                </option>
            )
        });
    }, []);

    return (
        <Flex direction={"row"}
              gap={3} p={3}
              alignItems={"flex-end"}>
            <Control inputID={"query"}
                     label={eventMembersT("query_field")}
                     value={store.filter.values.get("query") || ''}
                     onChange={onChangeQuery}/>

            <FormControl id={"route_id"}>
                <FormLabel>{eventMembersT("route_id")}</FormLabel>
                <Select value={store.filter.values.get("route_id")}
                        onChange={onChangeRouteID}>
                    <option value={''}>
                        {eventMembersT("select")}
                    </option>
                    {renderedRoutes.get()}
                </Select>
            </FormControl>

            <FormControl id={"gender"}>
                <FormLabel>{eventMembersT("gender")}</FormLabel>
                <Select value={store.filter.values.get("gender")}
                        onChange={onChangeGender}>
                    <option value={''}>
                        {eventMembersT("select")}
                    </option>
                    {renderedGenders}
                </Select>
            </FormControl>

            <FormControl id={"cloth_size"}>
                <FormLabel>{eventMembersT("cloth_size")}</FormLabel>
                <Select value={store.filter.values.get("cloth_size")}
                        onChange={onChangeClothSize}>
                    <option value={''}>
                        {eventMembersT("select")}
                    </option>
                    {renderedClothSizes}
                </Select>
            </FormControl>


            <IconButton  icon={<FaSearch />}
                         onClick={reloadMembers}/>
        </Flex>
    )
});

export default EventMembersFilter;
