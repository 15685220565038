import React, {useCallback} from 'react'
import {FormControl, FormErrorMessage, FormLabel, Input} from "@chakra-ui/react"

const Control = (props) => {
    const {
        inputID = "",
        error = "",
        type = 'text',
        label,
        value,
        onChange,
    } = props;

    const onChangeInput = useCallback((ev) => {
        onChange(ev.target.value);
    }, [onChange]);

    return (
        <FormControl id={inputID} isInvalid={!!error}>
            <FormLabel>{label}</FormLabel>
            <Input type={type}
                   value={value}
                   onChange={onChangeInput} />

            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    )
};

export default Control;
