import React, {useCallback, useEffect} from 'react'
import {LocalStoreProvider, useScreenStore, useStore} from "@lib/store"
import {Box, Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react"
import {observer} from "mobx-react"
import EventEditSkeleton from "@screens/event-edit/skeleton"
import eventEditScreen from "@store/models/eventEdit.screen"
import {useParams} from "react-router"
import LanguagesInput from "@ui/languages-input"
import {useTranslation} from "react-i18next"
import EventEditScreenDefault from "@screens/event-edit/components/default"
import EventEditScreenRoutes from "@screens/event-edit/components/routes"

const EventEditScreen = observer(() => {
    const store = useStore();

    const model = useScreenStore(eventEditScreen, {});
    const params = useParams();

    const { t: eventT } = useTranslation("event");

    useEffect(() => {
        model.load(params.id);
    }, [params.id]);

    const onChangeLanguageCode = useCallback(() => {

    }, []);


    if(!model.loaded) {
        return (
            <EventEditSkeleton />
        )
    }

    return (
        <LocalStoreProvider store={model}>
            <Box>
                <Flex mb={5}
                      direction="column"
                      bg={"white"}
                      p={5}
                      borderRadius={"lg"}>

                    <Flex mb={5} alignItems={"center"}>
                        <Text mr={3} fontWeight={"bold"}>
                            {eventT("title", {
                                name: model.event.title,
                            })}
                        </Text>

                        <Box>
                            <LanguagesInput languages={store.ui.languages}
                                            value={model.languageCode}
                                            onChange={onChangeLanguageCode}/>
                        </Box>
                    </Flex>

                    <Flex w={"100%"}>
                        <Tabs w={"100%"}>
                            <TabList>
                                <Tab>{eventT("common_tab")}</Tab>
                                <Tab>{eventT("routes_tab")}</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <EventEditScreenDefault />
                                </TabPanel>
                                <TabPanel>
                                    <EventEditScreenRoutes />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                    </Flex>
                </Flex>
            </Box>
        </LocalStoreProvider>
    );
});

export default EventEditScreen;
