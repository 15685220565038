import {types} from "mobx-state-tree"

const countryModel = types.model("country", {
    id: types.optional(types.number, 0),
    name: types.optional(types.string, "")
});

export const userItemModel = types.model("userItem", {
    id: types.optional(types.number, 0),
    first_name: types.optional(types.string, ""),
    last_name: types.optional(types.string, ""),
    country: types.optional(countryModel, {}),
    birthday_date: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    cloth_size: types.optional(types.string, ""),
    avatar_url: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    gender: types.optional(types.string, ""),
    email_activated: types.optional(types.boolean, false),
    banned: types.optional(types.boolean, false),
})
