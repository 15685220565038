import {createRootStore} from "@features/store/root.store"
import {applySnapshot} from "mobx-state-tree"

let store;

// eslint-disable-next-line
export default (initialData) => {
    const _store = store ?? createRootStore();

    if(initialData) {
        applySnapshot(_store, initialData);
    }

    if(typeof window === "undefined") return _store;
    if (!store) store = _store

    return _store
}
