export const GENDER_LIST = [
    {
        id: 'male',
        name: 'Мужской',
    },
    {
        id: 'female',
        name: 'Женский',
    },
];
