import React, {useCallback} from 'react'
import {FormControl, FormErrorMessage, FormLabel, Textarea} from "@chakra-ui/react"

const TextareaControl = (props) => {
    const {
        inputID = "",
        error = "",
        label,
        size = 'sm',
        value,
        onChange,
    } = props;

    const onChangeInput = useCallback((ev) => {
        onChange(ev.target.value);
    }, [onChange]);

    return (
        <FormControl id={inputID} isInvalid={!!error}>
            <FormLabel>{label}</FormLabel>
            <Textarea value={value}
                      size={size}
                      onChange={onChangeInput} />
            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    )
};

export default TextareaControl;
