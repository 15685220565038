import React from 'react'
import {Box, Skeleton} from "@chakra-ui/react"

const EventEditSkeleton = () => {
    return (
        <Box>
            <Skeleton height={"300px"} />
            <Skeleton height={"300px"} />
        </Box>
    )
};

export default EventEditSkeleton;
