import {useState} from "react"
import {formModel} from "@lib/form"

export default ($schema) => {
    const [state] = useState(
        () => formModel($schema()).create()
    )

    return state;
};
