import {useStore} from "@lib/store"
import {Navigate} from "react-router"
import {Suspense} from "react"

const WithAuth = (Component, isAuthorized) => {
    if(!isAuthorized) {
        return <Navigate to="/login" replace />;
    }

    return (
        <Suspense>
            <Component />
        </Suspense>
    )
};

export default WithAuth;
