import {flow, types} from "mobx-state-tree"
import partnerModel from "@store/models/partner.model"
import layoutRequests from "@app/services/http/requests/layout"

const partnersScreen = types.model("partners", {
    loaded: types.optional(types.boolean, false),
    items: types.optional(types.array(partnerModel), []),
}).actions((self) => ({
    load: flow(function* load() {
        try {
            const response = yield layoutRequests.partners();
            self.setItems(response.data.data);
            self.setLoaded(true);
        } catch(e) {
            console.error("Failed to load partners", e);
        }
    }),

    updatePartner: (id, values, imageFile) => {
        return layoutRequests.editPartner(id, values, imageFile);
    },

    createPartner: (values, imageFile) => {
        return layoutRequests.createPartner(values, imageFile);
    },

    updatePartners: flow(function* update() {
        const response = yield layoutRequests.partners();
        self.setItems(response.data.data);
    }),

    deletePartner: (id) => {
        return layoutRequests.deletePartner(id);
    },

    setItems: (value) => {
        self.items = value;
    },

    setLoaded: (value) => {
        self.loaded = value;
    },
}));

export default partnersScreen;
