import React, {useCallback, useContext} from 'react'
import {Box, useColorModeValue, Image, Flex, Text, ButtonGroup, Button} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import {computed} from "mobx"

const EventRouteCard = (props) => {
    const {
        canDelete = false,
        item = null,
        onEdit,
        onDelete,
    } = props;

    const { t: eventT } = useTranslation("event");

    const renderedMembersCount = computed(() =>  {
        if(item.members_limit === 0) {
            return item.members_count;
        }

        return `${item.members_count} / ${item.members_limit}`;
    });

    const onEditHandler = useCallback(() => {
        onEdit(item);
    }, [onEdit]);

    const onDeleteHandler = useCallback(() => {
        onDelete(item);
    }, [onDelete]);

    return (
        <Flex w="100%" >
            <Box
                bg={useColorModeValue('white', 'gray.800')}
                borderWidth="1px"
                w={"100%"}
                rounded="lg"
                shadow="sm"
                position="relative">
                <Image
                    src={item.thumbnail_url}
                    w={"100%"}
                    h={250}
                    objectFit={"cover"}
                />

                <Box p={6}>
                    <Flex justifyContent="space-between" alignContent="center">
                        <Box
                            fontSize="2xl"
                            fontWeight="bold"
                            as="h4"
                            lineHeight="tight"
                            isTruncated>
                            {item.title}
                        </Box>
                    </Flex>

                    <Flex w={"100%"} my={2}>
                        <Box fontSize="sm" color={useColorModeValue('gray.800', 'white')}>
                            {item.description}
                        </Box>
                    </Flex>

                    <Flex justifyContent="space-between" alignContent="center">
                        <Flex direction={"column"}>
                            <Box fontSize="md" fontWeight={"bold"}
                                 color={useColorModeValue('gray.800', 'white')}>
                                {item.distance}
                            </Box>

                            <Box fontSize="md" color={useColorModeValue('gray.800', 'white')}>
                                {renderedMembersCount.get()} {eventT("members_unit")}
                            </Box>
                        </Flex>

                        <Flex fontSize="xl"
                              alignItems={"center"}
                              color={useColorModeValue('gray.800', 'white')}>
                            <Text mr={2}>
                                {item.price}
                            </Text>

                            <Box as="span" color={'gray.600'} fontSize="lg">
                                тг.
                            </Box>
                        </Flex>
                    </Flex>

                    <Box mt={3}>
                        <ButtonGroup>
                            <Button colorScheme={'gray'} onClick={onEditHandler}>{eventT("edit")}</Button>
                            {canDelete && (
                                <Button colorScheme={'red'} onClick={onDeleteHandler}>
                                    {eventT("delete")}
                                </Button>
                            )}
                        </ButtonGroup>
                    </Box>
                </Box>
            </Box>
        </Flex>
    )
};

export default EventRouteCard;
