import React, {useMemo} from 'react'
import {Select} from "@chakra-ui/react"

const LanguagesInput = (props) => {
    const {
        languages = [],
        value,
        onChange,
    } = props;

    const renderedLanguages = useMemo(() => {
        return languages.map((language) => {
            return (
                <option value={language.code} key={`input-${language.code}`}>
                    {language.name}
                </option>
            )
        });
    }, [languages]);

    return (
        <Select value={value} onChange={onChange}>
            {renderedLanguages}
        </Select>
    )
};

export default LanguagesInput;
