import {useMemo} from 'react'

/**
 * @param {types.model} model
 * @param {Object} snapshot
 */
// eslint-disable-next-line
export default (model, snapshot = {}) => {
    const store = useMemo(() => {
        return model.create(snapshot);
        // eslint-disable-next-line
    }, []);

    return store;
};
