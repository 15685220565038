import React from 'react'
import {Flex, Icon, Link} from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

const SideMenuItem = (props) => {
    const {
        icon,
        link,
        children
    } = props;

    return (
        <Link to={link}
              as={NavLink}
              style={{
                  textDecoration: 'none'
              }}
              _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

export default SideMenuItem;
