import React, {useCallback, useContext, useMemo, useState} from "react"
import {LocalStoreContext} from "@lib/store"
import {observer} from "mobx-react"
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Text,
    useToast
} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import TextareaControl from "@ui/textarea-control"
import Control from "@ui/control"
import { Switch } from '@chakra-ui/react'
import FileSelector from "@ui/file-selector"
import EventStatus from "@features/events/status.enum"
import Partners from "@ui/partners"
import {renderError} from "@lib/form"
import Editor from "@ui/editor"
import EventLegalLinks from "@ui/event-legal-links"

const EventEditScreenDefault = observer(() => {
    const store = useContext(LocalStoreContext);
    const { t: eventT } = useTranslation("event");
    const { t: fieldsT } = useTranslation("fields");
    const toast = useToast();

    const [bannerFile, setBannerFile] = useState(null);
    const [backgroundFile, setBackgroundFile] = useState(null);

    const onChangeTitle = useCallback((text) => {
        return store.form.onChangeText("title", text)
    }, [store.form]);

    const onChangeShortDescription = useCallback((text) => {
        return store.form.onChangeText("short_description", text)
    }, [store.form]);

    const onChangeDescription = useCallback((text) => {
        return store.form.onChangeText("description", text)
    }, [store.form]);

    const onChangePhotoGalleryURL = useCallback((text) => {
        return store.form.onChangeText("photo_gallery_url", text)
    }, [store.form]);

    const onChangeVideoGalleryURL = useCallback((text) => {
        return store.form.onChangeText("video_gallery_url", text)
    }, [store.form]);

    const onChangeResultsURL = useCallback((text) => {
        return store.form.onChangeText("results_url", text)
    }, [store.form]);

    const onChangeRegisterClosed = useCallback((ev) => {
        return store.form.onChangeText("register_closed", ev.target.checked)
    }, [store.form]);

    const onChangeBackground = useCallback((ev) => {
        setBackgroundFile(ev.target.files[0]);
    }, [setBackgroundFile]);

    const onChangeBanner = useCallback((ev) => {
        setBannerFile(ev.target.files[0]);
    }, [setBannerFile]);

    const onChangeStatus = useCallback((ev) => {
        return store.form.onChangeText("status", ev.target.value);
    }, [store.form]);

    const onChangeStartAt = useCallback((text) => {
        return store.form.onChangeText("start_at", text);
    }, [store.form]);

    const onChangeShowInSlider = useCallback((ev) => {
        return store.form.onChangeText("show_in_slider", ev.target.checked)
    }, [store.form]);

    const onChangeFavorite = useCallback((ev) => {
        return store.form.onChangeText("favorite", ev.target.checked)
    }, [store.form]);

    const renderedStatuses = useMemo(() => {
        return EventStatus.valueOf().map((status) => {
            return (
                <option value={status}>{eventT(`statuses.${status.toLowerCase()}`)}</option>
            );
        });
    }, [eventT]);

    const saveEvent = useCallback(async () => {
        if(!await store.form.validate()) {
            return false;
        }

        try {
            await store.updateEvent(backgroundFile, bannerFile);
            toast({
                title: eventT("updated_success"),
                status: "success",
            });
        } catch(e) {
            console.error(e);
            toast({
                title: eventT("error_message"),
                description: `Error code: ${e.response.data.data.code}`,
                status: "error",
            });
        }
    }, [store.updateEvent, backgroundFile, bannerFile, eventT, toast]);

    return (
        <Box w={"100%"}>
            <Flex w={"100%"} mb={3}>
                <Control inputID={"title"}
                         label={eventT("title_field")}
                         error={renderError(fieldsT, store.form.errors.get("title"))}
                         value={store.form.values.get("title") || ''}
                         onChange={onChangeTitle}/>
            </Flex>
            <Flex w={"100%"} mb={3}>
                <TextareaControl inputID={"short_description"}
                                 label={eventT("short_description_field")}
                                 size={'sm'}
                                 error={renderError(fieldsT, store.form.errors.get("short_description"))}
                                 value={store.form.values.get("short_description") || ''}
                                 onChange={onChangeShortDescription}/>
            </Flex>
            <Flex w={"100%"} mb={3}>
                <Editor inputID={"description"}
                        label={eventT("description_field")}
                        value={store.form.values.get("description") || ''}
                        onChange={onChangeDescription}/>
            </Flex>

            <Flex w={"100%"} direction="row" mb={3} gap={3}>
                <Control inputID={"photo_gallery_url"}
                         label={eventT("photo_gallery_url_field")}
                         error={renderError(fieldsT, store.form.errors.get("photo_gallery_url"))}
                         value={store.form.values.get("photo_gallery_url") || ''}
                         onChange={onChangePhotoGalleryURL}/>
                <Control inputID={"video_gallery_url"}
                         label={eventT("video_gallery_url_field")}
                         error={renderError(fieldsT, store.form.errors.get("video_gallery_url"))}
                         value={store.form.values.get("video_gallery_url") || ''}
                         onChange={onChangeVideoGalleryURL}/>
            </Flex>

            <Flex w={"100%"} mb={3}>
                <FormControl id={"status"}>
                    <FormLabel>{eventT("status_field")}</FormLabel>
                    <Select value={store.form.values.get("status") || ''}
                            onChange={onChangeStatus}>
                        {renderedStatuses}
                    </Select>
                </FormControl>
            </Flex>

            <Flex w={"100%"} mb={3}>
                <Control inputID={"start_at"}
                         label={eventT("start_at_field")}
                         type={"datetime-local"}
                         error={renderError(fieldsT, store.form.errors.get("start_at"))}
                         value={(store.form.values.get("start_at") || '')}
                         onChange={onChangeStartAt}/>
            </Flex>


            <Flex w={"100%"} mb={3} justifyContent={"center"} alignItems={"center"}>
                <Flex mr={3} w={"100%"}>
                    <Control inputID={"results_url"}
                             label={eventT("results_url_field")}
                             error={renderError(fieldsT, store.form.errors.get("results_url"))}
                             value={store.form.values.get("results_url") || ''}
                             onChange={onChangeResultsURL}/>
                </Flex>
            </Flex>

            <Flex w="100%" mb={3} gap={3}>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='register-closed' mb='0'>
                        {eventT("register_closed_switch")}
                    </FormLabel>
                    <Switch id='register-closed'
                            isChecked={store.form.values.get("register_closed")}
                            onChange={onChangeRegisterClosed}/>
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='show-in-slider-switch' mb='0'>
                        {eventT("show_in_slider_switch")}
                    </FormLabel>
                    <Switch id='show-in-slider-switch'
                            isChecked={store.form.values.get("show_in_slider")}
                            onChange={onChangeShowInSlider}/>
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='favorite' mb='0'>
                        {eventT("favorite_switch")}
                    </FormLabel>
                    <Switch id='favorite'
                            isChecked={store.form.values.get("favorite")}
                            onChange={onChangeFavorite}/>
                </FormControl>
            </Flex>

            <Flex gap={5} mb={10} h={360} maxW={"4xl"}>
                <Box mb={3} w={"100%"}  h={"100%"}>
                    <Box  h={"100%"}>
                        <Text fontSize={"md"} fontWeight={"semibold"} mb={2}>
                            {eventT("banner_url")}
                        </Text>
                        <FileSelector value={bannerFile}
                                      fileURL={store.form.values.get("banner_url")}
                                      onChange={onChangeBanner} />
                    </Box>
                </Box>

                <Box mb={3} w={"100%"}  h={"100%"}>
                    <Box  h={"100%"}>
                        <Text fontSize={"md"} fontWeight={"semibold"} mb={2}>
                            {eventT("background_url")}
                        </Text>
                        <FileSelector value={backgroundFile}
                                      fileURL={store.form.values.get("background_url")}
                                      onChange={onChangeBackground} />
                    </Box>
                </Box>
            </Flex>

            <Flex w={"100%"} my={5} py={5} bg={"white"} borderRadius={"lg"}>
                <Partners value={store.partnerIDs}
                          onChange={store.setPartnerIDs} />
            </Flex>

            <Flex w={"100%"} my={5} py={5}>
                <EventLegalLinks values={store.legalLinks}
                                 onChange={store.editLegalLink}
                                 onDelete={store.deleteLegalLink}
                                 addEmptyItem={store.addEmptyLegalLink} />
            </Flex>

            <Flex w={"100%"}>
                <Button colorScheme='green' onClick={saveEvent}>{eventT("save")}</Button>
            </Flex>
        </Box>
    )
});

export default EventEditScreenDefault;
