import React, {useCallback} from 'react'
import {Drawer, DrawerContent, Flex, Text} from "@chakra-ui/react"
import SideMenuItem from "@ui/side-menu-item"
import {FaHome, FaLayerGroup, FaRunning, FaReact, FaUsers} from "react-icons/fa"
import {useTranslation} from "react-i18next"

const SideMenu = () => {
    const dummyClose = useCallback(() => {}, []);
    const { t: commonT } = useTranslation("common");

    return (
        <Drawer
            variant={'alwaysOpen'}
            isOpen={true}
            trapFocus={false}
            blockScrollOnMount={false}
            returnFocusOnClose={false}
            size={"sm"}
            placement="left"
            onClose={dummyClose}>
            <DrawerContent>
                <Flex alignItems={"center"}
                      p={5}>
                    <Text fontSize={"2xl"}>
                        Aqjaiyq Run Club
                    </Text>
                </Flex>

                <SideMenuItem link={"/"} icon={FaHome}>
                    {commonT("home")}
                </SideMenuItem>
                <SideMenuItem link={"/events"} icon={FaRunning}>
                    {commonT("events")}
                </SideMenuItem>
                <SideMenuItem link={"/users"} icon={FaUsers}>
                    {commonT("users")}
                </SideMenuItem>
                <SideMenuItem link={"/settings"} icon={FaLayerGroup}>
                    {commonT("settings")}
                </SideMenuItem>

                <SideMenuItem link={"/partners"} icon={FaReact}>
                    {commonT("partners")}
                </SideMenuItem>
            </DrawerContent>
        </Drawer>
    );
};

export default SideMenu;
