import React from 'react'
import Providers from "@app/providers"
import '@lib/yup'
import '@features/locales'
import AppContent from "@features/app/content"
import { BrowserRouter } from "react-router-dom"

const App = () => {
    return (
        <BrowserRouter>
            <Providers>
                <AppContent />
            </Providers>
        </BrowserRouter>
    )
};

export default App;
