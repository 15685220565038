import React, {useCallback, useRef} from 'react'
import {FormControl, FormLabel} from "@chakra-ui/react"
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {
    Bold,
    Table,
    Essentials,
    Indent,
    IndentBlock,
    Italic,
    List,
    Paragraph,
    Undo, ClassicEditor,
    Heading,
    Link
} from 'ckeditor5'

const Editor = (props) => {
    const {
        value,
        inputID,
        label,
        onChange
    } = props;

    const editorRef = useRef(null);

    const handleChange = useCallback(() => {
        onChange(editorRef.current.getData());
    }, [onChange]);

    return (
        <FormControl id={inputID}>
            <FormLabel>{label}</FormLabel>
            <CKEditor
                value={value}
                editor={ClassicEditor}
                onChange={handleChange}
                onReady={(editor) => {
                    editorRef.current = editor;
                }}
                config={{
                    style: {
                        height: 500
                    },
                    toolbar: [
                        'undo', 'redo', '|',
                        'heading', '|', 'bold', 'italic', '|',
                        'link', 'insertTable', '|',
                        'bulletedList', 'numberedList', 'indent', 'outdent'
                    ],
                    plugins: [
                        Bold,
                        Essentials,
                        Heading,
                        Indent,
                        IndentBlock,
                        Italic,
                        Link,
                        List,
                        Paragraph,
                        Table,
                        Undo
                    ],
                    initialData: value,
                }}
            />
        </FormControl>

    )
};

export default Editor;
