import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
    Box, Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Text
} from "@chakra-ui/react"
import {renderError, useFormStore} from "@lib/form"
import {useTranslation} from "react-i18next"
import Control from "@ui/control"
import FileSelector from "@ui/file-selector"
import { object, string } from 'yup'
import {observer} from "mobx-react"

const $schema = () => {
    return object({
        link: string().required()
    });
};

const PartnersActionModal = observer((props) => {
    const {
        partner = null,
        isOpen = false,
        onAction,
        onClose
    } = props;

    const form = useFormStore($schema);
    const { t: partnersT } = useTranslation("partners");
    const { t: fieldsT } = useTranslation("fields");

    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        if(isOpen) {
            if(!partner) {
                form.setValues({});
            } else {
                form.setValues({
                    link: partner.link,
                });
            }

            setImageFile(null);
        }
    }, [isOpen]);

    const onChangeLink = useCallback((text) => {
        form.onChangeText("link", text);
    }, [form]);

    const onChangeImage = useCallback((ev) => {
        setImageFile(ev.target.files[0]);
    }, [setImageFile]);

    const onCreateHandler = useCallback(async () => {
        if(!(await form.validate())) {
            return false;
        }

        onAction(form.values, imageFile);
        onClose();
    }, [onAction, imageFile]);

    const headerText = useMemo(() => {
        if(partner !== null) {
            return partnersT("edit_modal.title");
        }

        return partnersT("create_modal.title");
    }, [partnersT, partner]);

    return (
        <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {headerText}
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Flex mb={3}>
                        <Control error={renderError(fieldsT, form.errors.get("link"))}
                                 value={form.values.get("link") || ''}
                                 label={partnersT("link")}
                                 inputID={"link"}
                                 onChange={onChangeLink}/>
                    </Flex>


                    <Flex mb={3} w={"md"}  h={"md"}>
                        <Box w={"100%"} h={"100%"}>
                            <Text fontSize={"md"} fontWeight={"semibold"} mb={2}>
                                {partnersT("image")}
                            </Text>
                            <FileSelector value={imageFile}
                                          fileURL={partner ? partner.image_url : null}
                                          onChange={onChangeImage} />
                        </Box>
                    </Flex>

                </ModalBody>

                <ModalFooter justifyContent={"left"}>
                    <Button colorScheme={"green"} onClick={onCreateHandler}>
                        {partnersT("save")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
});

export default PartnersActionModal;
