import {useStore} from "@lib/store"
import {Navigate} from "react-router"
import {createElement} from "react"

const WithPublic = (component) => {
    const store = useStore();

    if(store.user?.is_authorized) {
        return <Navigate to="/" replace />;
    }

    return createElement(component);
};

export default WithPublic;
