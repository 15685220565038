import {Route, Routes} from "react-router"
import Login from "@screens/login"
import WithAuth from "@features/router/decorators/withAuth"
import WithPublic from "@features/router/decorators/withPublic"
import LayoutContainer from "@ui/layout-container"
import {lazy} from "react"
import EventEdit from "@screens/event-edit"
import PartnersScreen from "@screens/partners"
import EventMembersScreen from "@screens/event-members"
import {observer} from "mobx-react"
import {useStore} from "@lib/store"
import UsersScreen from "@screens/users"

const Home = lazy(() => import("@screens/home"));
const Events = lazy(() => import("@screens/events"));
const Settings = lazy(() => import("@screens/settings"));

const Router = observer(() => {
    const store = useStore();

    return (
        <Routes>
            <Route path="/login" element={WithPublic(Login)} />
            <Route element={<LayoutContainer />}>
                <Route path="/" element={WithAuth(Home, store.user.is_authorized)} />
                <Route path="/users" element={WithAuth(UsersScreen, store.user.is_authorized)} />
                <Route path="/events" element={WithAuth(Events, store.user.is_authorized)} />
                <Route path="/settings" element={WithAuth(Settings, store.user.is_authorized)} />
                <Route path="/event/:id" element={WithAuth(EventEdit, store.user.is_authorized)} />
                <Route path="/partners" element={WithAuth(PartnersScreen, store.user.is_authorized)} />
                <Route path="/event/:id/members" element={WithAuth(EventMembersScreen, store.user.is_authorized)} />
            </Route>
        </Routes>
    );
});

export default Router;

