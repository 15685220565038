import axios from "axios"

const _axios = axios.create();

const setBaseURL = (url) => {
    _axios.defaults.baseURL = url;
};

const setHeader = (key, value) => {
    _axios.defaults.headers.common[key] = value;
};

const setAuthorization = (type, token) => {
    setHeader("Authorization", [type, token].filter((i) => i?.length > 0 || false).join(" "));
};

const instance = () => {
    return _axios;
};

const http = {
    _axios,
    instance,
    setBaseURL,
    setAuthorization,
    setHeader
};

export default http;
