import {types} from "mobx-state-tree"
import {userModel} from "./models/user"
import uiModel from "@store/models/ui.model"

const rootStore = types.model("rootStore", {
    loaded: types.optional(types.boolean, false),
    user: types.optional(userModel, {}),
    ui: types.optional(uiModel, {}),
}).actions((self) => ({
    setLoaded: (value) => {
        self.loaded = value;
    }
}));

const createRootStore = () => {
    const _user = userModel.create({});
    const _ui = uiModel.create({});

    return rootStore.create({
        user: _user,
        ui: _ui,
    })
};

export {
    rootStore,
    createRootStore
};
