import http from "@lib/http"

const create = (title) => {
    return http.instance().post("/admin/v1/event", {
        title
    });
};

const get = (id) => {
    return http.instance().get(`/admin/v1/event/${id}`);
};

const routes = (id) => {
    return http.instance().get(`/admin/v1/event/${id}/routes`);
};

const createRoute = (eventID, data, thumbnail) => {
    let formData = new FormData()

    Object.entries(data).forEach(([k, v]) => {
        formData.append(k, v);
    });

    formData.append("thumbnail", thumbnail);

    return http.instance().post(`/admin/v1/event/${eventID}/route`, formData);
};

const editRoute = (routeID, data, thumbnail) => {
    let formData = new FormData()

    formData.append("id", routeID);
    Object.entries(data).forEach(([k, v]) => {
        formData.append(k, v);
    });

    formData.append("thumbnail", thumbnail);

    return http.instance().patch(`/admin/v1/event/route`, formData);
};

const deleteRoute = (eventID, routeID) => {
    return http.instance().delete(`/admin/v1/event/${eventID}/${routeID}/route`);
};

const edit = (id, data, background, banner) => {
    let formData = new FormData()

    Object.entries(data).forEach(([k, v]) => {
        if(v instanceof Array && v.some((i) => typeof i === "object")) {
            formData.append(k, JSON.stringify(v));
        } else {
            formData.append(k, v);
        }
    });

    formData.append("background", background);
    formData.append("banner", banner);

    return http.instance().patch(`/admin/v1/event/${id}`, formData);
};

const members = (eventID, page, limit, data) => {
    return http.instance().get(`/admin/v1/event/${eventID}/members`, {
        params: {
            page,
            limit,
            ...data
        }
    });
};

const toExcel = (eventID, data) => {
    return http.instance().get(`/admin/v1/event/${eventID}/members/export`, {
        params: data,
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

const deleteMember = (userID, eventID) => {
    return http.instance().delete(`/admin/v1/event/${eventID}/members/${userID}`);
};

const eventRequests = {
    create,
    get,
    routes,
    createRoute,
    editRoute,
    deleteRoute,
    edit,
    members,
    deleteMember,
    toExcel
};

export default eventRequests;
