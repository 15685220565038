import React, {useCallback, useMemo} from 'react'
import {useTranslation} from "react-i18next"
import {Flex, Image, Menu, MenuButton, MenuDivider, MenuItem, MenuList} from "@chakra-ui/react"
import {sortable} from "react-sortable"

const PartnersItem = (props) => {
    const {
        partnerID = 0,
        imageURL = "",
        selected = false,
        canSelect = false,
        onSelect,
        onEdit,
        onDelete,
    } = props;

    const { t: partnersT } = useTranslation("partners");

    const onEditHandler = useCallback(() => {
        onEdit(partnerID);
    }, [partnerID, onEdit]);

    const onDeleteHandler = useCallback(() => {
        onDelete(partnerID);
    }, [partnerID, onDelete]);

    const onSelectHandler = useCallback(() => {
        onSelect(partnerID);
    }, [partnerID, onSelect]);

    const renderedMenuList = useMemo(() => {
        const list = [
            onEdit && (
                <MenuItem onClick={onEditHandler}>
                    {partnersT("edit")}
                </MenuItem>
            ),
            onDelete && (
                <MenuItem onClick={onDeleteHandler}>
                    {partnersT("delete")}
                </MenuItem>
            )
        ].filter((v) => v);

        if(canSelect) {
            const addDivider = list.length > 0;

            if(addDivider) {
                list.unshift((
                    <MenuDivider />
                ));
            }

            list.unshift(...[
                (
                    <MenuItem onClick={onSelectHandler}>
                        {partnersT(selected ? "unselect" : "select")}
                    </MenuItem>
                ),
            ]);

        }

        return list;
    }, [onEdit, onDelete, partnerID, canSelect, selected, onSelect])

    return (
        <Menu>
            <MenuButton>
                <Flex alignItems={"center"} w={"96px"} h={"96px"}
                      px={2}
                      bg={selected ?  "gray.200" : "gray.50"}>
                    <Image src={imageURL} />
                </Flex>
            </MenuButton>
            <MenuList>
                {renderedMenuList}
            </MenuList>
        </Menu>
    )
};

export default PartnersItem;
