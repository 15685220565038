import React, {useCallback} from 'react'
import {Flex} from "@chakra-ui/react"
import Control from "@ui/control"

const MultipleInputItem = (props) => {
    const {
        itemID,
        labelText,
        label,
        labelError = '',
        valueText,
        value,
        valueError = '',
        onChangeLabel,
        onChangeValue,
    } = props;

    const onChangeLabelHandler = useCallback((text) => {
        onChangeLabel(itemID, text);
    }, [onChangeLabel, itemID]);

    const onChangeValueHandler = useCallback((text) => {
        onChangeValue(itemID, text);
    }, [onChangeValue, itemID]);

    return (
        <Flex p={3} bg={"gray.50"} gap={3} borderRadius={"lg"}>
            <Control inputID={"label"}
                     error={labelError}
                     label={labelText}
                     value={label}
                     onChange={onChangeLabelHandler} />
            <Control inputID={"value"}
                     error={valueError}
                     label={valueText}
                     value={value}
                     onChange={onChangeValueHandler} />
        </Flex>
    )
};

export default MultipleInputItem;
