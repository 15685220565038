import React, {useCallback, useEffect} from 'react'
import {useParams} from "react-router"
import {LocalStoreProvider, useScreenStore} from "@lib/store"
import {Box, Flex, Heading, Skeleton, Spacer} from "@chakra-ui/react"
import UsersTable from "@screens/users/table"
import {observer} from "mobx-react"
import Pagination from "@/ui/pagination"
import {useTranslation} from "react-i18next"
import UsersFilter from "@screens/users/filter"
import {usersModel} from "@store/models/users/users.model"

const UsersScreen = observer(() => {
    const model = useScreenStore(usersModel, {});
    const params = useParams();

    const { t: usersT } = useTranslation("users");

    useEffect(() => {
        (async function() {
            model.setLoaded(false);
            await model.load();
            model.setLoaded(true);
        }());
    }, [params.id]);

    const reloadUsers = useCallback(async () => {
        model.setLoaded(false);
        await model.load();
        model.setLoaded(true);
    }, [model]);

    const onChangePage = useCallback(async (page) => {
        model.pagination.changePage(page);

        model.setLoaded(false);
        await model.load();
        model.setLoaded(true);
    }, [model.pagination.changePage]);

    if(!model.loaded) {
        return (
            <Box>
                <Skeleton height={"64px"} mb={3} />
                <Skeleton height={"300px"} />
            </Box>
        )
    }

    return (
        <LocalStoreProvider store={model}>
            <Box>
                <Box bg={'white'} p={3} borderRadius={"lg"} mb={5}>
                    <Flex minWidth='max-content' alignItems='center' gap='2'>
                        <Box p='2'>
                            <Heading size='md'>{usersT("title")}</Heading>
                        </Box>
                        <Spacer />
                    </Flex>
                </Box>

                <Box bg={"white"} p={3} borderRadius={"lg"} mb={5}>
                    <UsersFilter reloadUsers={reloadUsers} />
                </Box>

                <Box bg={"white"} p={3} borderRadius={"lg"} mb={5}>
                    <UsersTable users={model.users} reloadUsers={reloadUsers} />
                </Box>


                <Box bg={"white"} borderRadius={"lg"} p={3}>
                    <Pagination currentPage={model.pagination.page}
                                pageCount={model.pagination.pagesCount}
                                onChange={onChangePage}/>
                </Box>
            </Box>
        </LocalStoreProvider>
    );
});

export default UsersScreen;
