import React, {useCallback} from 'react'
import {Box, Flex, Text} from "@chakra-ui/react"

const PaginationItem = (props) => {
    const {
        page,
        active,
        onClick
    } = props;

    const onClickHandler = useCallback(() => {
        onClick(page);
    }, [onClick, page]);

    return (
        <Flex w={10}
             mW={48}
              h={10}
             flex={"0 1 auto"}
             p={3}
             justifyContent={"center"}
             alignItems={"center"}
             cursor={"pointer"}
             bg={!active ? 'none' : 'cyan.500'}
             borderRadius={'lg'}
             borderWidth={'1px'}
             onClick={onClickHandler}>
            <Text fontSize={'xs'}>{page}</Text>
        </Flex>
    )
};

export default PaginationItem;
