import {useStore} from "@lib/store"
import {useEffect} from "react"
import {getFromStorage} from "@utils"
import {USER_AUTH_TOKEN_LOCAL_STORAGE_KEY} from "@app/constants/user"
import http from "@lib/http"
import Router from "@features/router"
import {observer} from "mobx-react"

const AppContent = observer(() => {
    const store = useStore();

    useEffect(() => {
        (async function() {
            const token = getFromStorage(USER_AUTH_TOKEN_LOCAL_STORAGE_KEY);
            http.setBaseURL(process.env.REACT_APP_API_URL);

            await Promise.allSettled([
                store.ui.loadLanguages(),
                store.ui.loadCountries()
            ]);

            if(token) {
                http.setAuthorization("Bearer", token);
                await store.user.loadUser();
            }

            store.setLoaded(true);
        }());
        // eslint-disable-next-line
    }, [store.user.is_authorized]);

    if(!store.loaded) {
        return null;
    }

    return (
        <Router />
    )
});

export default AppContent;
