export const USER_CLOTHING_SIZE_LIST = [
    {
        id: 'xs',
        name: 'XS'
    },
    {
        id: 's',
        name: 'S'
    },
    {
        id: 'm',
        name: 'M'
    },
    {
        id: 'l',
        name: 'L'
    },
    {
        id: 'xl',
        name: 'XL'
    },
    {
        id: 'xxl',
        name: 'XXL'
    },
    {
        id: '2xl',
        name: '2XL'
    },
]
