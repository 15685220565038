import React, {useCallback, useRef} from 'react'
import {Box, Image} from '@chakra-ui/react'
import {computed} from "mobx"
import {IconButton, Input} from "@chakra-ui/react"
import {FaEdit} from "react-icons/fa"

const FileSelector = (props) => {
    const {
        fileURL = '',
        value = null,
        onChange,
    } = props;

    const inputRef = useRef(null);

    const onOpenInput = useCallback(() => {
        inputRef.current.click();
    }, []);

    const localURL = computed(() => {
        if(value !== null) {
            return URL.createObjectURL(value);
        }

        return fileURL
    });

    return (
        <Box w={"100%"} h={"100%"}

             position={"relative"}>
            {localURL.get() !== "" && (
                <Image src={localURL.get()}
                       borderRadius={"lg"}
                       w={"100%"}
                       h={"100%"}/>
            )}

            <Box w={"100%"} style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,

                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                background: "rgba(0, 0, 0, .3)",
                zIndex: 1
            }} borderRadius={"lg"}>
                <IconButton icon={<FaEdit />}
                            bg={"gray.50"}
                            borderRadius={"lg"}
                            onClick={onOpenInput} />
            </Box>

            <Input type={"file"}
                   style={{
                       display: "none",
                   }}
                   onChange={onChange}
                   ref={inputRef} />
        </Box>
    )
};

export default FileSelector;
