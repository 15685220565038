import React from 'react'
import {StoreProvider} from "@lib/store"
import {ChakraProvider, extendTheme} from '@chakra-ui/react'

const Providers = ({ children }) => {
    const theme = extendTheme({
        components: {
            Drawer: {
                variants: {
                    alwaysOpen: {
                        parts: ['dialog, dialogContainer'],
                        dialog: {
                            pointerEvents: 'auto',
                        },
                        dialogContainer: {
                            pointerEvents: 'none',
                        },
                    },
                },
            },
        }
    });

    return (
        <StoreProvider>
            <ChakraProvider theme={theme}>
                {children}
            </ChakraProvider>
        </StoreProvider>
    )
};

export default Providers;
