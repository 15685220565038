import {flow, types} from "mobx-state-tree"
import partnerModel from "@store/models/partner.model"
import layoutRequests from "@app/services/http/requests/layout"

const partnersModel = types.model("partners", {
    loaded: types.optional(types.boolean, false),
    items: types.optional(types.array(partnerModel), []),
    showAll: types.optional(types.boolean, false),
}).actions((self) => ({
    load: flow(function* load() {
        try {
            const response = yield layoutRequests.partners();
            self.setItems(response.data.data);
            self.setLoaded(true);
        } catch(e) {
            console.error("Failed to load partners", e);
        }
    }),

    setShowAll: (value) => {
        self.showAll = value;
    },

    setItems: (value) => {
        self.items = value;
    },

    setLoaded: (value) => {
        self.loaded = value;
    }
}));

export default partnersModel;
