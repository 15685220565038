import { setLocale } from "yup"

/**
 * Translation keys for Yup errors
 */
setLocale({
    mixed: {
        default: ({ key: 'field_invalid', values: { }}),
        required: () => ({ key: 'required', values: { }})
    },
    string: {
        required: () => ({ key: 'required', values: { }}),
        length: ({ length }) => ({ key: 'exactly_length', values: { length }}),
        email: () => ({ key: 'invalid_email', values: { }})
    },
    number: {
        min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
        max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
    },
});
