import {flow, types} from "mobx-state-tree"
import eventEditEventModel from "@store/models/event-edit/event.model"
import eventRequests from "@app/services/http/requests/event"
import { object,string } from 'yup'
import {formModel} from "@lib/form"
import eventLegalLinkModel from "@store/models/events/eventLegalLink.model"
import {toJS} from "mobx"

const $schema = () => {
    return object({
        short_description: string().max(130)
    });
};

const eventEditScreen = types.model("eventEdit", {
    languageCode: types.optional(types.string, ""),
    loaded: types.optional(types.boolean, false),
    event: types.optional(eventEditEventModel, {}),
    form: types.optional(formModel($schema()), {}),
    partnerIDs: types.optional(types.array(types.number), []),
    legalLinks: types.optional(types.array(eventLegalLinkModel), []),
}).actions((self) => ({
    load: flow(function* load(id) {
        self.setLoaded(false);
        try {
            const response = yield eventRequests.get(id);
            self.createEvent(response.data.data);
            self.setLoaded(true);
        } catch(e) {
            console.error("Failed to load event", e);
        }
    }),

    updateRoutes: flow(function* update() {
        try {
            const response = yield eventRequests.routes(self.event.id);
            self.event.setRoutes(response.data.data);
        } catch(e) {
            console.error("Failed to get routes", e);
        }
    }),

    createRoute: (data, thumbnail) => {
        const values = Object.fromEntries(data);

        return eventRequests.createRoute(self.event.id, {
            ...values,
            start_at: values.start_at + ":00+05:00"
        }, thumbnail);
    },

    updateRoute: flow(function* update(routeID, data, thumbnail) {
        const values = Object.fromEntries(data);

        return eventRequests.editRoute(routeID, {
            ...values,
            start_at: values.start_at + ":00+05:00"
        }, thumbnail);
    }),

    deleteRoute: (id) => {
        return eventRequests.deleteRoute(self.event.id, id);
    },

    updateEvent: (background, banner) => {
        const values = Object.fromEntries(self.form.values);

        return eventRequests.edit(
            self.event.id,
            {
                ...values,
                start_at: values.start_at + ":00+05:00",
                status: values.status?.toUpperCase() || '',
                partner_ids: self.partnerIDs,
                legal_links: toJS(self.legalLinks),
            },
            background,
            banner,
        );
    },

    setLoaded: (value) => {
        self.loaded = value;
    },

    createEvent: (snapshot) => {
        self.event = eventEditEventModel.create(snapshot);
        self.form.setValues({
            title: self.event.title,
            short_description: self.event.short_description,
            description: self.event.description,
            photo_gallery_url: self.event.photo_gallery_url,
            video_gallery_url: self.event.video_gallery_url,
            start_at: self.event.start_at?.replace(":00+05:00", "") || "",
            status: self.event.status,
            results_url: self.event.results_url,
            banner_url: self.event.banner_url,
            background_url: self.event.background_url,
            register_closed: self.event.register_closed,
            show_in_slider: self.event.show_in_slider,
            favorite: self.event.favorite
        });

        self.setPartnerIDs(self.event.partners.map((v) => v.id));
        self.setLegalLinks(snapshot.legal_links);
    },

    setPartnerIDs: (value) => {
        self.partnerIDs = value;
    },

    setLegalLinks: (value) => {
        self.legalLinks = value;
    },

    editLegalLink: (id, title, description, buttonLink, buttonText) => {
        const legalLink = self.legalLinks.find((_, i) => i  === id);
        legalLink.setTitle(title);
        legalLink.setDescription(description);
        legalLink.setButtonLink(buttonLink);
        legalLink.setButtonText(buttonText);
    },

    deleteLegalLink: (id) => {
        const legalLinks = self.legalLinks.filter((v) => v.id !== id)
        self.legalLinks.replace(legalLinks);
    },

    addEmptyLegalLink: () => {
        self.legalLinks.push(eventLegalLinkModel.create({}));
    }
}));

export default eventEditScreen;
