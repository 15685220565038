export default class EventStatus {
    static DRAFT = 'DRAFT';
    static PENDING = 'PENDING';
    static ACTIVE = 'active';
    static ARCHIVED = 'ARCHIVED';

    static valueOf() {
        return [EventStatus.DRAFT, EventStatus.PENDING, EventStatus.ARCHIVED];
    }
}
