import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
    Box,
    Button, Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import {number, object, string} from 'yup'
import {renderError, useFormStore} from "@lib/form"
import Control from "@ui/control"
import {observer} from "mobx-react"
import FileSelector from "@ui/file-selector"
import TextareaControl from "@ui/textarea-control"

const $schema = () => {
    return object({
        title: string().required(),
        description: string().required().max(130),
        distance: string().required(),
        gpx_url: string(),
        price: number(),
        members_limit: number()
    });
};

const EventsActionRouteModal = observer((props) => {
    const {
        event = null,
        route = null,
        isOpen = false,
        onAction,
        onClose
    } = props;

    const form = useFormStore($schema);
    const { t: eventT } = useTranslation("event");
    const { t: fieldsT } = useTranslation("fields");

    const [thumbnailFile, setThumbnailFile] = useState(null);

    useEffect(() => {
        if(isOpen) {
            if(!route) {
                form.setValues({});
            } else {
                form.setValues({
                    title: route.title,
                    description: route.description,
                    members_limit: route.members_limit,
                    price: route.price,
                    distance: route.distance,
                    gpx_url: route.gpx_url,
                    start_at: route.start_at.replace(":00+05:00", ""),
                });
            }

            setThumbnailFile(null);
        }
    }, [isOpen]);

    const onChangeTitle = useCallback((text) => {
        form.onChangeText("title", text);
    }, [form]);

    const onChangeDescription = useCallback((text) => {
        form.onChangeText("description", text);
    }, [form]);

    const onChangeDistance = useCallback((text) => {
        form.onChangeText("distance", text);
    }, [form]);

    const onChangeGPXUrl = useCallback((text) => {
        form.onChangeText("gpx_url", text);
    }, [form]);

    const onChangePrice = useCallback((text) => {
        form.onChangeText("price", text);
    }, [form]);

    const onChangeMembersLimit = useCallback((text) => {
        form.onChangeText("members_limit", text);
    }, [form]);

    const onChangeFile = useCallback((ev) => {
        setThumbnailFile(ev.target.files[0]);
    }, [setThumbnailFile]);

    const onCreateHandler = useCallback(async () => {
        if(!(await form.validate())) {
            return false;
        }

        console.log(form.values);
        onAction(form.values, thumbnailFile);
        onClose();
    }, [onAction, thumbnailFile]);

    const headerText = useMemo(() => {
        if(route !== null) {
            return eventT("edit_route_modal.title", {
                title: event.title,
                route: route.title,
            });
        }

        return eventT("create_route_modal.title", {
            title: event.title,
        });
    }, [eventT, event, route]);

    const onChangeStartAt = useCallback((text) => {
        return form.onChangeText("start_at", text);
    }, [form]);

    return (
        <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {headerText}
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Flex mb={3}>
                        <Control error={renderError(fieldsT, form.errors.get("title"))}
                                 value={form.values.get("title") || ''}
                                 label={eventT("create_route_modal.title_label")}
                                 inputID={"title"}
                                 onChange={onChangeTitle}/>
                    </Flex>

                    <Flex mb={3}>
                        <TextareaControl error={renderError(fieldsT, form.errors.get("description"))}
                                         value={form.values.get("description") || ''}
                                         label={eventT("create_route_modal.description_label")}
                                         inputID={"description"}
                                         onChange={onChangeDescription}/>
                    </Flex>

                    <Flex mb={3}>
                        <Control error={renderError(fieldsT, form.errors.get("distance"))}
                                 value={form.values.get("distance") || ''}
                                 label={eventT("create_route_modal.distance_label")}
                                 inputID={"distance"}
                                 onChange={onChangeDistance}/>
                    </Flex>

                    <Flex mb={3}>
                        <Control error={renderError(fieldsT, form.errors.get("gpx_url"))}
                                 value={form.values.get("gpx_url") || ''}
                                 label={eventT("create_route_modal.gpx_url_label")}
                                 inputID={"gpx_url"}
                                 onChange={onChangeGPXUrl}/>
                    </Flex>

                    <Flex w={"100%"} mb={3}>
                        <Control inputID={"start_at"}
                                 label={eventT("create_route_modal.start_at")}
                                 type={"datetime-local"}
                                 error={form.errors.get("start_at")}
                                 value={(form.values.get("start_at") || '')}
                                 onChange={onChangeStartAt}/>
                    </Flex>

                    <Flex mb={3} direction={"row"} gap={3}>
                        <Control error={renderError(fieldsT, form.errors.get("price"))}
                                 value={form.values.get("price") || 0}
                                 label={eventT("create_route_modal.price_label")}
                                 inputID={"price"}
                                 onChange={onChangePrice}/>

                        <Control error={renderError(fieldsT, form.errors.get("members_limit"))}
                                 value={form.values.get("members_limit") || 0}
                                 label={eventT("create_route_modal.members_limit_label")}
                                 inputID={"members_limit"}
                                 onChange={onChangeMembersLimit}/>
                    </Flex>

                    <Flex mb={3} w={"md"}  h={"md"}>
                        <Box w={"100%"} h={"100%"}>
                            <Text fontSize={"md"} fontWeight={"semibold"} mb={2}>
                                {eventT("thumbnail_url")}
                            </Text>
                            <FileSelector value={thumbnailFile}
                                          fileURL={route ? route.thumbnail_url : null}
                                          onChange={onChangeFile} />
                        </Box>
                    </Flex>
                </ModalBody>

                <ModalFooter justifyContent={"left"}>
                    <Button colorScheme={"green"} onClick={onCreateHandler}>
                        {eventT("save")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
});

export default EventsActionRouteModal;
