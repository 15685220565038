import initializeStore from "./initialize"
import {createContext} from "react"

export const StoreContext = createContext(null)

const LocalStoreContext = createContext(null);

const LocalStoreProvider = ({ children, store }) => {
    return <LocalStoreContext.Provider value={store}>{children}</LocalStoreContext.Provider>
};

const StoreProvider = ({ children, initialData  }) => {
    const initializedStore = initializeStore(initialData)

    return (
        <StoreContext.Provider value={initializedStore}>{children}</StoreContext.Provider>
    )
}

export {
    StoreProvider,
    LocalStoreProvider,
    LocalStoreContext
}
