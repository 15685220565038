import {flow, types} from "mobx-state-tree"
import userRequests from "@app/services/http/requests/user"
import {setToStorage} from "@utils"
import {USER_AUTH_TOKEN_LOCAL_STORAGE_KEY} from "@app/constants/user"

export const userModel = types.model("userModel", {
    id: types.optional(types.number, 0),
    first_name: types.optional(types.string, ""),
    last_name: types.optional(types.string, ""),
    avatar_url: types.optional(types.string, ""),
    is_authorized: types.optional(types.boolean, false),

    auth_token: types.optional(types.string, ""),
}).actions((self) => ({
    setID: (value) => {
        self.id = value;
    },

    setFirstName: (value) => {
        self.first_name = value;
    },

    setLastName: (value) => {
        self.last_name = value;
    },

    setAvatarURL: (value) => {
        self.avatar_url = value;
    },

    setUser: (user) => {
        self.setID(user.id);
        self.setFirstName(user.first_name);
        self.setLastName(user.last_name);
        self.setAvatarURL(user.avatar_url);
    },

    setAuthToken: (token) => {
        self.auth_token = token;
    },

    setAuthTokenToStorage: (token) => {
        self.setAuthToken(token);
        setToStorage(USER_AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
    },

    setIsAuthorized: (value) => {
        self.is_authorized = value;
    },

    loadUser: flow(function* loadUser() {
        try {
            const response = yield userRequests.short();
            const user = response.data.data;

            self.setID(user.id);
            self.setFirstName(user.first_name);
            self.setLastName(user.last_name);
            self.setAvatarURL(user.avatar_url);
            self.setIsAuthorized(true);
        } catch(e) {
            console.log("Failed to authorize user");
            console.error(e);
        }
    }),

    logout: () => {
        self.setID(0);
        self.setFirstName("");
        self.setLastName("");
        self.setAvatarURL("");
        self.setIsAuthorized(false);
    }
})).views((self) => ({
    get formattedName() {
        return self.first_name + " " + self.last_name;
    }
}));
