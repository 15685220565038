import React from 'react'
import {Box, Heading,  Flex, IconButton} from "@chakra-ui/react"
import MultipleInputItem from "@ui/multiple-input-item"
import {FaPlus} from "react-icons/fa"
import {computed} from "mobx"
import {observer} from "mobx-react"
import EventLegalLinksItem from "@ui/event-legal-links-item"
import {useTranslation} from "react-i18next"

const EventLegalLinks = observer((props) => {
    const {
        values,
        onChange,
        onDelete,
        addEmptyItem,
    } = props;

    const [t] = useTranslation("event");

    const renderedValues = computed(() => {
        return values.slice().map((item, index) => {
            return (
                <Box mb={3} key={`legal-links-${index}`}>
                    <EventLegalLinksItem itemID={index}
                                         title={item.title}
                                         description={item.description}
                                         buttonText={item.button_text}
                                         buttonLink={item.button_link}
                                         onChange={onChange}
                                         onDelete={onDelete}/>
                </Box>
            );
        });
    });

    return (
        <Box>
            <Flex w={"100%"} alignItems='center' gap='5' mb={3}>
                <Box p='2'>
                    <Heading size='md'>{t("legal_links_title")} ({values.length})</Heading>
                </Box>
                <IconButton icon={<FaPlus />} onClick={addEmptyItem} />
            </Flex>

            {renderedValues.get()}
        </Box>
    )
});

export default EventLegalLinks;
