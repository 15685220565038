import React, {useCallback, useMemo} from 'react'
import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import {computed} from "mobx"
import {format, parseISO} from "date-fns"
import eventRequests from "@app/services/http/requests/event";
import userRequests from "@app/services/http/requests/user";

const UsersTable = (props) => {
    const {
        users,
        reloadUsers
    } = props;

    const { t: usersT } = useTranslation("users");

    const banUser = useCallback(async (item) => {
        const result = window.confirm(item.banned ? 'Вы точно хотите разбанить пользователя?' : 'Вы точно хотите забанить пользователя?');

        if(result) {
            await userRequests.banUser(item.id, !item.banned);
            reloadUsers();
        }
    }, [reloadUsers]);

    const renderedData = useMemo(() => {
        return computed(() => {
            return users.slice().map((item) => {
                return (
                    <Tr>
                        <Td>{item.id}</Td>
                        <Td>{`${item.last_name} ${item.first_name}`}</Td>
                        <Td>{item.gender !== "" ? usersT(`gender_${item.gender}`) : '-'}</Td>
                        <Td>{item.country?.name || '-'}</Td>
                        <Td>{item.cloth_size || '-'}</Td>
                        <Td>{item.phone || '-'}</Td>
                        <Td>{item.birthday_date.startsWith("0001") || item.birthday_date === "" ? "-" :
                            format(parseISO(item.birthday_date), "dd.MM.yyyy")}</Td>
                        <Td>{item.phone || '-'}</Td>
                        <Td>
                            <Button colorScheme={"red"} onClick={() => banUser(item)}>
                                {item.banned ? 'Разбанить' : 'Забанить'}
                            </Button>
                        </Td>
                    </Tr>
                );
            })
        }).get();
    }, [users]);

    return (
        <TableContainer>
            <Table variant='striped' colorScheme='gray'>
                <Thead>
                    <Tr>
                        <Th>{usersT('id')}</Th>
                        <Th>{usersT('name')}</Th>
                        <Th>{usersT('gender')}</Th>
                        <Th>{usersT('country')}</Th>
                        <Th>{usersT('cloth_size')}</Th>
                        <Th>{usersT('phone')}</Th>
                        <Th>{usersT('birthday_date')}</Th>
                        <Th>{usersT('actions')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderedData}
                </Tbody>
            </Table>
        </TableContainer>
    )
};

export default UsersTable;
