import React from 'react'
import {
    Box,
    useColorModeValue,
} from '@chakra-ui/react'
import SideMenu from "@ui/side-menu"
import Header from "@ui/header"
import {Outlet} from "react-router"

const LayoutContainer = () => {
    return (
        <Box bg={useColorModeValue('gray.100', 'gray.900')}>
            <SideMenu />
            <Header />
            <Box style={{ overflowY: "scroll", height: "calc(100vh - 80px)" }}
                 ml={{ base: 460, md: 460 }} p="4">
                <Outlet />
            </Box>
        </Box>
    );
};

export default LayoutContainer
