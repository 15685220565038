export default (exception) => {
    const errors = {};

    exception.inner.forEach((error) => {
        errors[error.path] = error.errors.map((error) => {
            if(typeof error === "string") {
                return {
                    key: error,
                    values: {}
                };
            }

            return error;
        });
    });

    return errors;
};
