import React, {useCallback, useEffect} from 'react'
import {useParams} from "react-router"
import {LocalStoreProvider, useScreenStore} from "@lib/store"
import {Box, Button, ButtonGroup, Flex, Heading, Skeleton, Spacer} from "@chakra-ui/react"
import eventMembersScreen from "@store/models/eventMembers.screen"
import EventMembersTable from "@screens/event-members/table"
import {observer} from "mobx-react"
import Pagination from "@/ui/pagination"
import {useTranslation} from "react-i18next"
import EventMembersFilter from "@screens/event-members/filter"

const EventMembersScreen = observer(() => {
    const model = useScreenStore(eventMembersScreen, {});
    const params = useParams();

    const { t: eventMembersT } = useTranslation("event_members");

    useEffect(() => {
        (async function() {
            model.setLoaded(false);
            await model.load(params.id);
            await model.loadMembers();
            model.setLoaded(true);
        }());
    }, [params.id]);

    const reloadMembers = useCallback(async (page) => {
        model.setLoaded(false);
        await model.loadMembers();
        model.setLoaded(true);
    }, [model]);

    const onChangePage = useCallback(async (page) => {
        model.pagination.changePage(page);

        model.setLoaded(false);
        await model.loadMembers();
        model.setLoaded(true);
    }, [model.pagination.changePage]);

    if(!model.loaded) {
        return (
            <Box>
                <Skeleton height={"64px"} mb={3} />
                <Skeleton height={"300px"} />
            </Box>
        )
    }

    return (
        <LocalStoreProvider store={model}>
            <Box>
                <Box bg={'white'} p={3} borderRadius={"lg"} mb={5}>
                    <Flex minWidth='max-content' alignItems='center' gap='2'>
                        <Box p='2'>
                            <Heading size='md'>{eventMembersT("title", {
                                title: model.event.title
                            })}</Heading>
                        </Box>
                        <Spacer />
                        <ButtonGroup gap='2'>
                            <Button colorScheme='gray' onClick={model.exportToExcel}>Экспорт в Excel</Button>
                        </ButtonGroup>
                    </Flex>
                </Box>

                <Box bg={"white"} p={3} borderRadius={"lg"} mb={5}>
                    <EventMembersFilter reloadMembers={reloadMembers} />
                </Box>

                <Box bg={"white"} p={3} borderRadius={"lg"} mb={5}>
                    <EventMembersTable headers={model.table.headers.slice()}
                                       data={model.table.data.slice()}
                                       reloadMembers={reloadMembers} />
                </Box>


                <Box bg={"white"} borderRadius={"lg"} p={3}>
                    <Pagination currentPage={model.pagination.page}
                                pageCount={model.pagination.pagesCount}
                                onChange={onChangePage}/>
                </Box>
            </Box>
        </LocalStoreProvider>
    );
});

export default EventMembersScreen;
