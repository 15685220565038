import {types} from "mobx-state-tree"

const paginationModel = types.model("pagination", {
    page: types.optional(types.number, 1),
    limit: types.optional(types.number, 20),
    totalCount: types.optional(types.number, 0),
}).actions((self) => ({
    setPagination: (page, limit, totalCount) => {
        self.limit = limit
        self.page = page
        self.totalCount = totalCount
    },

    changePage: (page) => {
        self.page = page
    }
})).views((self) => ({
    get pagesCount() {
        return Math.ceil(self.totalCount / self.limit);
    }
}));

export default paginationModel;
