import {flow, types} from "mobx-state-tree"
import {action, toJS} from "mobx"
import getValidationErrors from "./utils/getValidationErrors.util"

const errorModel = types.model("Error", {
    key: types.string,
    values: types.optional(
        types.map(types.union(
            types.string, types.number, types.boolean
        )), {}
    )
})

export default ($schema) => {
    return types.model("Form", {
        values: types.map(types.union(
            types.string, types.number, types.boolean
        )),
        errors: types.map(types.union(types.array(errorModel), types.string)),
    }).actions((self) => ({
        onChangeText(key, value) {
            self.values.set(key, value);
        },

        addError(key, value) {
            self.errors.set(key, value)
        },

        deleteError(key) {
            self.errors.delete(key);
        },

        setErrors(errors) {
            self.errors.replace(errors);
        },

        setValues(values) {
            self.values.replace(values)
        },

        validate: flow(function* validate() {
            if(!$schema) {
                throw new Error('Trying to call validate function without validationSchema');
            }

            self.errors.clear();

            try {
                yield $schema.validate(
                    Object.fromEntries(toJS(self.values)),
                    {
                        abortEarly: false
                    }
                );

                return true;
            } catch (e) {
                if(e.errors) {
                    self.setErrors(getValidationErrors(e));
                    return false;
                }

                // Unhandled exception
                console.error(e);
                return null;
            }
        })
    }));
};

