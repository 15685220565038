import {types} from "mobx-state-tree"
import {formModel} from "@lib/form"
import {number, object, string} from "yup"
import {userItemModel} from "@store/models/users/userItem.model"
import {flow} from "mobx"
import userRequests from "@app/services/http/requests/user"
import paginationModel from "@store/models/pagination"

const $schema = () => {
    return object({
        query: string(),
        cloth_size: string(),
        country_id: number(),
        gender: string(),
        birthday_date: string(),
    });
};

export const usersModel = types.model("users", {
    loading: types.optional(types.boolean, true),
    filter: types.optional(formModel($schema()), {}),
    users: types.optional(types.array(userItemModel), []),
    pagination: types.optional(paginationModel, {}),
}).actions((self) => ({
    setLoaded: (value) => {
        self.loaded = value;
    },

    setUsers: (value) => {
        self.users = value
    },

    load: flow(function* load() {
        try {
            const response = yield userRequests.list(
                Object.fromEntries(self.filter.values),
                self.pagination.page,
                self.pagination.limit,
            );

            self.pagination.setPagination(
                parseInt(response.headers['x-pagination-page']),
                parseInt(response.headers['x-pagination-limit']),
                parseInt(response.headers['x-pagination-total-count']),
            )

            self.setUsers(response.data.data);
        } catch(e) {
            console.error("Failed to load event members", e);
        }
    })
}));
