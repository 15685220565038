import React, {useCallback} from 'react'
import {Flex, IconButton} from "@chakra-ui/react"
import Control from "@ui/control"
import {FaCross, FaTimes} from "react-icons/fa"
import {useTranslation} from "react-i18next"

const EventLegalLinksItem = (props) => {
    const {
        itemID,
        title,
        description,
        buttonLink,
        buttonText,
        onChange,
        onDelete,
    } = props;

    const [t] = useTranslation("event");

    const onChangeTitleHandler = useCallback((text) => {
        onChange(itemID, text, description, buttonLink, buttonText);
    }, [onChange, itemID, description, buttonLink, buttonText]);

    const onChangeDescriptionHandler = useCallback((text) => {
        onChange(itemID, title, text, buttonLink, buttonText);
    }, [onChange, itemID, title, buttonLink, buttonText]);

    const onChangeButtonLinkHandler = useCallback((text) => {
        onChange(itemID, title, description, text, buttonText);
    }, [onChange, itemID, title, description, buttonText]);

    const onChangeButtonTextHandler = useCallback((text) => {
        onChange(itemID, title, description, buttonLink, text);
    }, [onChange, itemID, title, description, buttonLink]);

    const onDeleteHandler = useCallback(() => {
        onDelete(itemID);
    }, [onDelete]);

    return (
        <Flex p={3} bg={"gray.50"} gap={3} borderRadius={"lg"} alignItems={"center"}>
            <Control inputID={"title"}
                     label={t("legal_link_title")}
                     value={title}
                     onChange={onChangeTitleHandler} />
            <Control inputID={"description"}
                     label={t("legal_link_description")}
                     value={description}
                     onChange={onChangeDescriptionHandler} />
            <Control inputID={"button_link"}
                     label={t("legal_link_button_link")}
                     value={buttonLink}
                     onChange={onChangeButtonLinkHandler} />
            <Control inputID={"button_text"}
                     label={t("legal_link_button_text")}
                     value={buttonText}
                     onChange={onChangeButtonTextHandler} />

            <IconButton icon={<FaTimes />} onClick={onDeleteHandler} />
        </Flex>
    )
};

export default EventLegalLinksItem;
