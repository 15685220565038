import React, {useCallback, useContext, useMemo} from 'react'
import {Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react"
import {LocalStoreContext} from "@lib/store";
import eventRequests from "@app/services/http/requests/event";
import {observer} from "mobx-react";

const EventMembersTable = observer((props) => {
    const {
        headers,
        data,
        reloadMembers
    } = props;

    const context = useContext(LocalStoreContext);

    const deleteUserFromEvent = useCallback(async (item) => {
        const result = window.confirm('Вы точно хотите удалить пользователя из забега?');

        if(result) {
            await eventRequests.deleteMember(item.user_id, context.event.id);
            reloadMembers();
        }
    }, [reloadMembers, context.event.id]);

    const renderedHeaders = useMemo(() => {
        return [
            ...headers,
            {
                key: "actions",
                label: "Действия"
            }
        ].map((header) => {
            return (
                <Th key={header.key}>{header.label}</Th>
            )
        });
    }, [headers]);

    const renderedData = useMemo(() => {
        return data.map((item) => {
            const tds = headers.map((v) => {
                let value = item[v.key];

                if(v.key === "cloth_size") {
                    value = value.toUpperCase();
                }

                return (
                    <Td>{value}</Td>
                );
            });

            return (
                <Tr>
                    {tds}

                    <Td>
                        <Button colorScheme={"red"} onClick={() => deleteUserFromEvent(item)}>
                            X
                        </Button>
                    </Td>
                </Tr>
            );
        });
    }, [data, headers]);

    return (
        <TableContainer>
            <Table variant='striped' colorScheme='gray'>
                <Thead>
                    <Tr>
                        {renderedHeaders}
                    </Tr>
                </Thead>
                <Tbody>
                    {renderedData}
                </Tbody>
            </Table>
        </TableContainer>
    )
});

export default EventMembersTable;
