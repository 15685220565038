import React, {useCallback} from 'react'
import {
    Flex,
    useColorModeValue,
    Menu,
    MenuButton,
    Avatar,
    HStack,
    VStack,
    MenuList,
    MenuItem,
    Text,
    Box,
} from "@chakra-ui/react"
import {useTranslation} from "react-i18next"
import {useStore} from "@lib/store"
import { FaChevronDown } from 'react-icons/fa'
import {observer} from "mobx-react"
import {setToStorage} from "@utils"
import {USER_AUTH_TOKEN_LOCAL_STORAGE_KEY} from "@app/constants/user"
import {useNavigate} from "react-router"

const Header = observer(() => {
    const store = useStore();
    const { t: headerT } = useTranslation("header");

    const navigate = useNavigate()

    const logout = useCallback(() => {
        store.user.logout();
        setToStorage(USER_AUTH_TOKEN_LOCAL_STORAGE_KEY, "");
        navigate("/login");
    }, [navigate]);

    return (
        <Flex
            ml={{ base: 449, md: 449 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    src={store.user.avatar_url}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{store.user.formattedName}</Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FaChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem onClick={logout}>{headerT("logout")}</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
});

export default Header;
