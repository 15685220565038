import http from "@lib/http"

const short = () => {
   return http.instance().get("/v1/user/short")
};

const login = (email, password) => {
    return http.instance().post("/v1/user/login", {
        email,
        password
    });
};

const list = (filter, page, limit) => {
    return http.instance().get("/admin/v1/user/list", {
        params: {
            ...filter,
            page,
            limit
        }
    });
};

const banUser = (user_id, value) => {
    return http.instance().post("/admin/v1/user/banned", {
        user_id,
        value
    });
};


const userRequests = {
    short,
    login,
    list,
    banUser
};

export default userRequests;
