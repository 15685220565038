import {StoreContext} from "./provider"
import {useContext} from "react"

/** @returns {rootStore} */
// eslint-disable-next-line
export default () => {
    const context = useContext(StoreContext)
    if (context === undefined) {
        throw new Error('useStore must be used within StoreProvider')
    }

    return context || {}
}
