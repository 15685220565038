import {types} from "mobx-state-tree"
import {formModel} from "@lib/form"
import {object, string} from "yup"
import {flow} from "mobx"
import userRequests from "@app/services/http/requests/user"
import {USER_ALLOWED_FLAG} from "@app/constants/user"

const $schema = () => {
    return object({
        email: string().required().email(),
        password: string().required()
    });
};

/**
 * @type {{
 *     form: object,
 *     disabled: boolean
 *     login: function
 * }}
 */
const loginScreenModel = types.model("loginScreen", {
    form: types.optional(formModel($schema()), {}),
    disabled: types.optional(types.boolean, false),
}).actions((self) => ({
    login: flow(function* login() {
        try {
            const email = self.form.values.get("email");
            const password = self.form.values.get("password");

            const response = yield userRequests.login(email, password);
            if((USER_ALLOWED_FLAG&response.data.data.user.flags) !== USER_ALLOWED_FLAG) {
                return -2;
            }

            return response.data.data;
        } catch(e) {
            return -3;
        }
    }),
    
    setDisabled: (value) => {
        self.disabled = value;
    }
}));

export default loginScreenModel;
