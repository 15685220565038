import http from "@lib/http"

const get = (languageCode) => {
    return http.instance().get(`/v1/layout/${languageCode}`)
};

const update = (id, data) => {
    return http.instance().patch(`/admin/v1/layout/${id}`, data);
};

const languages = () => {
    return http.instance().get("/v1/layout/languages");
};

const partners = () => {
    return http.instance().get("/admin/v1/layout/partners");
};

const createPartner = (data, imageFile) => {
    let formData = new FormData()

    Object.entries(Object.fromEntries(data)).forEach(([k, v]) => {
        formData.append(k, v);
    });

    formData.append("image", imageFile);
    return http.instance().post("/admin/v1/layout/partners", formData);
};

const editPartner = (id, data, imageFile) => {
    let formData = new FormData()

    Object.entries(Object.fromEntries(data)).forEach(([k, v]) => {
        formData.append(k, v);
    });

    formData.append("image", imageFile);
    return http.instance().patch(`/admin/v1/layout/partners/${id}`, formData);
};

const deletePartner = (id) => {
    return http.instance().delete(`/admin/v1/layout/partners/${id}`);
};

const countries = () => {
    return http.instance().get("/v1/layout/countries");
};

const layoutRequests = {
    get,
    update,
    languages,
    partners,
    createPartner,
    editPartner,
    deletePartner,
    countries
};

export default layoutRequests;
