import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {computed} from "mobx"
import {Box, ButtonGroup, Flex, Heading, IconButton, Skeleton} from "@chakra-ui/react"
import partnersModel from "@store/models/partners.model"
import PartnersItem from "@ui/partners-item"
import {observer} from "mobx-react"
import {useTranslation} from "react-i18next"
import {FaPlus, FaCrosshairs, FaTimes} from "react-icons/fa"
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const Partners = observer((props) => {
    const {
        value = [],
        onChange
    } = props;

    const { t: partnersT } = useTranslation("partners");
    const [model] = useState(() => partnersModel.create({}));

    useEffect(() => {
        model.load();
        //eslint-disable-next-line
    }, []);

    const onSelectPartner = useCallback((partnerID) => {
        if(value.includes(partnerID)) {
            onChange(value.filter((v) => v !== partnerID))
        } else {
            onChange([...value, partnerID]);
        }
    }, [onChange, value]);

    const renderedPartners = useMemo(() => {
        return computed(() => {
            if(!model.loaded) {
                return null;
            }

            const mapper = (item, i) => {
                return (
                    <Draggable key={`partner-${item.id}`} draggableId={`${item.id}`} index={i}>
                        {(provided) => (
                            <div  ref={provided.innerRef}
                                  style={provided.draggableProps.style}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                <PartnersItem partnerID={item.id}
                                              imageURL={item.image_url}
                                              canSelect={true}
                                              selected={value.includes(item.id)}
                                              onSelect={onSelectPartner}/>
                            </div>
                        )}
                    </Draggable>
                );
            };

            if(model.showAll) {
                return model.items.slice()
                    .filter((item) => value.includes(item.id) && !model.showAll || model.showAll)
                    .map(mapper);
            }

            return value.map((v, i) => {
                const item = model.items.find((i) => i.id === v);
                return mapper(item, i);
            })
        });
        //eslint-disable-next-line
    }, [model.items, model.loaded, onSelectPartner, value, model.showAll])

    const toggleEdit = useCallback(() => {
        model.setShowAll(!model.showAll)
    }, [model]);

    const onDragEnd = useCallback((result) => {
        if(model.showAll) {
            return false;
        }

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const partnerIDs = reorder(
            value,
            result.source.index,
            result.destination.index
        );

        onChange(partnerIDs);
    }, [value, onChange, model.showAll]);

    if(!model.loaded) {
        return (
            <Skeleton height={"120px"} />
        );
    }

    const partners = renderedPartners.get();

    return (
        <>
            <Box p={3} borderRadius={"lg"}>
                <Flex mb={3} alignItems={"center"}>
                    <Heading mr={5} size={'md'}>
                        {partnersT("title")}
                    </Heading>

                    <ButtonGroup gap={3}>
                        <IconButton icon={model.showAll ? <FaTimes /> : <FaPlus />} onClick={toggleEdit} />
                    </ButtonGroup>
                </Flex>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {provided => (
                            <Flex wrap={"wrap"}
                                  gap={5} ref={provided.innerRef} {...provided.droppableProps}>
                                {partners}
                                {provided.placeholder}
                            </Flex>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
        </>
    )
});

export default Partners;
