import {types} from "mobx-state-tree"
import eventRouteModel from "@store/models/events/eventRoute"
import partnerModel from "@store/models/partner.model"
import eventLegalLinkModel from "@store/models/events/eventLegalLink.model"

const eventEditEventModel = types.model("eventEditEvent", {
    id: types.optional(types.number, 0),
    title: types.optional(types.string, ""),
    short_description: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    photo_gallery_url: types.optional(types.string, ""),
    video_gallery_url: types.optional(types.string, ""),
    start_at: types.maybeNull(types.optional(types.string, "")),
    status: types.optional(types.string, ""),
    results_url: types.optional(types.string, ""),
    register_closed: types.optional(types.boolean, false),

    show_in_slider: types.optional(types.boolean, false),
    favorite: types.optional(types.boolean, false),

    partners: types.optional(types.array(partnerModel), []),

    banner_url: types.optional(types.string, ""),
    background_url: types.optional(types.string, ""),

    routes: types.optional(types.array(eventRouteModel),[]),

    legal_links: types.optional(types.array(eventLegalLinkModel), []),
}).actions((self) => ({
    setRoutes: (value) => {
        self.routes = value;
    },

    setTitle: (value) => {
        self.title = value;
    },

    setShortDescription: (value) => {
        self.short_description = value;
    },

    setDescription: (value) => {
        self.description = value;
    },

    setPhotoGalleryURL: (value) => {
        self.photo_gallery_url = value;
    },

    setVideoGalleryURL: (value) => {
        self.vidoe_gallery_url = value;
    },

    setStartAt: (value) => {
        self.start_at = value;
    },

    setStatus: (value) => {
        self.status = value;
    },

    setResultsURL: (value) => {
        self.results_url = value;
    },

    setLegalLinks: (value) => {
        self.legal_links = value;
    },
}));

export default eventEditEventModel;
