import React, {useCallback, useContext, useMemo, useState} from 'react'
import {LocalStoreContext} from "@lib/store"
import {useTranslation} from "react-i18next"
import {Box, Button, Flex, useBoolean, useToast} from "@chakra-ui/react"
import {observer} from "mobx-react"
import {computed, toJS} from "mobx"
import EventRouteCard from "@ui/event-route-card"
import EventsActionRouteModal from "@features/events/components/action-route-modal"
import EventStatus from "@features/events/status.enum"

const EventEditScreenRoutes = observer(() => {
    const store = useContext(LocalStoreContext);
    const toast = useToast();
    const { t: eventT } = useTranslation("event");

    const [activeRoute, setActiveRoute] = useState(null);
    const [openedActionModal, manipulateOpenedActionModal] = useBoolean(false);

    const createRoute = useCallback(() => {
        setActiveRoute(null);
        manipulateOpenedActionModal.on();
    }, [manipulateOpenedActionModal.on]);

    const onEdit = useCallback((route) => {
        setActiveRoute(route);
        manipulateOpenedActionModal.on();
    }, [setActiveRoute, manipulateOpenedActionModal.on]);

    const onDelete = useCallback(async (route) => {
        await store.deleteRoute(route.id);
        await store.updateRoutes();

        toast({
            title: eventT("deleted_success"),
            status: "success",
        });
    }, [store, eventT]);

    const onActionInModal = useCallback(async (values, thumbnailFile) => {
        const jsValues = toJS(values);

        if(activeRoute) {
            await store.updateRoute(activeRoute.id, jsValues, thumbnailFile);
        } else {
            await store.createRoute(jsValues, thumbnailFile);
        }

        manipulateOpenedActionModal.off();
        await store.updateRoutes();

        if(activeRoute) {
            toast({
                title: eventT("updated_success"),
                status: "success",
            });
        } else {
            toast({
                title: eventT("created_success"),
                status: "success",
            });
        }
    }, [store, eventT, toast, manipulateOpenedActionModal, activeRoute]);

    const renderedRoutes = useMemo(() => {
        return computed(() => {
            return store.event.routes.map((route) => {
                return (
                    <Box w={"lg"} key={`route-${route.id}`}>
                        <EventRouteCard canDelete={store.event.status.toLowerCase() === EventStatus.DRAFT}
                                        item={route}
                                        onEdit={onEdit}
                                        onDelete={onDelete}/>
                    </Box>
                );
            })
        });
    }, [store.event.routes.slice(), store.event.status, onEdit, onDelete]);

    return (
        <Box>
            <EventsActionRouteModal event={store.event}
                                    route={activeRoute}
                                    isOpen={openedActionModal}
                                    onAction={onActionInModal}
                                    onClose={manipulateOpenedActionModal.off} />

            <Flex gap={5} mb={6}>
                {renderedRoutes.get()}
            </Flex>

            <Flex w={"100%"}>
                <Button colorScheme='green' onClick={createRoute}>
                    {eventT("create_route")}
                </Button>
            </Flex>
        </Box>
    );
});

export default EventEditScreenRoutes;
